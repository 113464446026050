import React, { FC } from "react";

import { graphql } from "gatsby";
import BlogsMain from "../components/BlogUiComponents/BlogsMain/BlogsMain";
import Layout from "../components/Layout/Layout";
import { UseTagsFilter } from "../components/hooks/UseTagsFilter";

type Props = {
  pageContext: any;
  data: any;
  location: any;
};

const TagsCloud: FC<Props> = ({ pageContext, data }) => {
  const { blogs, cloudBlogsPaginationNumber, currentPage, tag } = pageContext;
  const selectedTags = UseTagsFilter(
    "cloud-insights",
    blogs,
    data.allTags.nodes
  );

  let categoryItem = data.allSanityTag.nodes[0].title;

  let filteredArray: any = [];
  const checkPath = (data: any) => {
    data.allSanityBlog.edges.map((x: any) => {
      x.node.tags.map((y: any) => {
        if (y.title === categoryItem) {
          filteredArray.push(x);
        }
        return {};
      });
      return {};
    });
  };

  checkPath(data);

  return (
    <Layout location={"/blog"}>
      <>
        <BlogsMain
          basePathForUrl={"/cloud-insights"}
          basePathForPaginations={`/cloud-insights/${tag}`}
          blogName={"/cloud-insights"}
          data={filteredArray}
          numberPages={cloudBlogsPaginationNumber}
          currentPage={currentPage}
          tags={selectedTags}
        />
      </>
    </Layout>
  );
};

export default TagsCloud;

export const query = graphql`
  query ($tag: String, $skip: Int!, $limit: Int!) {
    allSanityBlog(
      limit: $limit
      skip: $skip
      filter: {
        blogType: { elemMatch: { title: { eq: "cloud-insights" } } }
        tags: { elemMatch: { title: { eq: $tag } } }
      }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          subtitle
          title
          blogType {
            title
          }
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          _rawBody
          publishedAt
          body {
            children {
              text
            }
          }
          tags {
            title
            _id
          }
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          alt
        }
      }
      pageInfo {
        totalCount
      }
    }
    allTags: allSanityTag {
      nodes {
        title
        _id
      }
    }
    allSanityTag(filter: { title: { eq: $tag } }) {
      nodes {
        title
        _id
      }
    }
  }
`;
